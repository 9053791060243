
#honoraires .swiper {
  height: auto;
  max-width: 600px;
}
#honoraires .swiper .swiper-slide {
  text-align: center;
}
#honoraires .swiper .swiper-slide img {
  height:300px;
}
    


